import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { RecycleScroller } from 'vue-virtual-scroller';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import { generateCustomValidity, EventType, webViewTypeItems, hasAccess, webViewType, ResponseType } from "./assets/utilities";
const AppMain = () => import(/* webpackPreload: true */ "./pages/main.vue");
const HomeIndex = () => import(/* webpackPrefetch: true */ "./pages/home/index.vue");
const homeAlert = () => import(/* webpackPrefetch: true */ "./pages/home/alert.vue");
const homeEvent = () => import(/* webpackPrefetch: true */ "./pages/home/event.vue");
const homeAbout = () => import(/* webpackPrefetch: true */ "./pages/home/about.vue");
const homeErrorIoGps = () => import(/* webpackPrefetch: true */ "./pages/home/errorIoGps.vue");

const evaluationQuestionnaire = () => import(/* webpackPrefetch: true */ "./pages/evaluation/questionnaire.vue");
const evaluationUpToDownQuestionnairesAnswers = () => import(/* webpackPrefetch: true */ "./pages/evaluation/uptodownquestionnairesanswers.vue");
const evaluationSelfQuestionnairesAnswers = () => import(/* webpackPrefetch: true */ "./pages/evaluation/selfquestionnairesanswers.vue");

const AccountLogin = () => import(/* webpackPrefetch: true */ "./pages/account/login.vue");
const AccountChangePass = () => import(/* webpackPrefetch: true */ "./pages/account/changePass.vue");
const accountEmployeeChangePass = () => import(/* webpackPrefetch: true */ "./pages/account/employeeChangePass.vue");
const accountSetting = () => import(/* webpackPrefetch: true */ "./pages/account/setting.vue");


const documentRegister = () => import(/* webpackPrefetch: true */ "./pages/document/register.vue");
const documentArchive = () => import(/* webpackPrefetch: true */ "./pages/document/archive.vue");
const documentDetail = () => import(/* webpackPrefetch: true */ "./pages/document/detail.vue");
const vacationRegister = () => import(/* webpackPrefetch: true */ "./pages/vacation/register.vue");
const vacationArchive = () => import(/* webpackPrefetch: true */ "./pages/vacation/archive.vue");
const vacationDetail = () => import(/* webpackPrefetch: true */ "./pages/vacation/detail.vue");
const vacationAlternateCartable = () => import(/* webpackPrefetch: true */ "./pages/vacation/alternateCartable.vue");

const missionRegister = () => import(/* webpackPrefetch: true */ "./pages/mission/register.vue");
const missionArchive = () => import(/* webpackPrefetch: true */ "./pages/mission/archive.vue");
const missionCartable = () => import(/* webpackPrefetch: true */ "./pages/mission/cartable.vue");
const missionDetail = () => import(/* webpackPrefetch: true */ "./pages/mission/detail.vue");
const editMissionDetail = () => import(/* webpackPrefetch: true */ "./pages/mission/editDetail.vue");
const missionCost = () => import(/* webpackPrefetch: true */ "./pages/mission/cost.vue");
const missionCartableDetail = () => import(/* webpackPrefetch: true */ "./pages/mission/missionDetail.vue");
const missionConfirmed = () => import(/* webpackPrefetch: true */ "./pages/mission/confirmed.vue");


const missionDetailArchive = () => import(/* webpackPrefetch: true */ "./pages/missionDetail/archive.vue");
const ioRegister = () => import(/* webpackPrefetch: true */ "./pages/io/register.vue");
const ioRegisterIo = () => import(/* webpackPrefetch: true */ "./pages/io/registerIo.vue");
const ioArchive = () => import(/* webpackPrefetch: true */ "./pages/io/archive.vue");
const ioDetail = () => import(/* webpackPrefetch: true */ "./pages/io/detail.vue");
const ioPersonnel = () => import(/* webpackPrefetch: true */ "./pages/io/personnel.vue");
const ioPermission = () => import(/* webpackPrefetch: true */ "./pages/io/permission.vue");
const ioInPeriod = () => import(/* webpackPrefetch: true */ "./pages/io/inPeriod.vue");

const ioGpsRegister = () => import(/* webpackPrefetch: true */ "./pages/ioGps/register.vue");
const ioGpsEdit = () => import(/* webpackPrefetch: true */ "./pages/ioGps/edit.vue");
const ioGpsArchive = () => import(/* webpackPrefetch: true */ "./pages/ioGps/archive.vue");

const standByRegister = () => import(/* webpackPrefetch: true */ "./pages/standBy/register.vue");
const standByArchive = () => import(/* webpackPrefetch: true */ "./pages/standBy/archive.vue");
const standByDetail = () => import(/* webpackPrefetch: true */ "./pages/standBy/detail.vue");

const workGroupRelocationRegister = () => import(/* webpackPrefetch: true */ "./pages/workGroupRelocation/register.vue");
const workGroupRelocationArchive = () => import(/* webpackPrefetch: true */ "./pages/workGroupRelocation/archive.vue");
const workGroupRelocationDetail = () => import(/* webpackPrefetch: true */ "./pages/workGroupRelocation/detail.vue");

const shiftSwapRegister = () => import(/* webpackPrefetch: true */ "./pages/shiftSwap/register.vue");
const shiftSwapArchive = () => import(/* webpackPrefetch: true */ "./pages/shiftSwap/archive.vue");
const shiftSwapDetail = () => import(/* webpackPrefetch: true */ "./pages/shiftSwap/detail.vue");
const shiftSwapCartable = () => import(/* webpackPrefetch: true */ "./pages/shiftSwap/cartable.vue");

const shiftRelocationRegister = () => import(/* webpackPrefetch: true */ "./pages/shiftRelocation/register.vue");
const shiftRelocationArchive = () => import(/* webpackPrefetch: true */ "./pages/shiftRelocation/archive.vue");
const shiftRelocationDetail = () => import(/* webpackPrefetch: true */ "./pages/shiftRelocation/detail.vue");

const extraWorkRegister = () => import(/* webpackPrefetch: true */ "./pages/extraWork/register.vue");
const extraWorkArchive = () => import(/* webpackPrefetch: true */ "./pages/extraWork/archive.vue");
const extraWorkDetail = () => import(/* webpackPrefetch: true */ "./pages/extraWork/detail.vue");

const specialExtraWorkRegister = () => import(/* webpackPrefetch: true */ "./pages/specialExtraWork/register.vue");
const specialExtraWorkArchive = () => import(/* webpackPrefetch: true */ "./pages/specialExtraWork/archive.vue");
const specialExtraWorkDetail = () => import(/* webpackPrefetch: true */ "./pages/specialExtraWork/detail.vue");

const customRegister = () => import(/* webpackPrefetch: true */ "./pages/custom/register.vue");
const customArchive = () => import(/* webpackPrefetch: true */ "./pages/custom/archive.vue");
const customDetail = () => import(/* webpackPrefetch: true */ "./pages/custom/detail.vue");

const sharedWorkFlow = () => import(/* webpackPrefetch: true */ "./pages/shared/workFlow.vue");

const dailyFunctionalityIndex = () => import(/* webpackPrefetch: true */ "./pages/dailyfunctionality/index.vue");
const dailyFunctionalityDetail = () => import(/* webpackPrefetch: true */ "./pages/dailyfunctionality/detail.vue");
const dailyFunctionalityEvent = () => import(/* webpackPrefetch: true */ "./pages/dailyfunctionality/event.vue");

const periodFunctionalityIndex = () => import(/* webpackPrefetch: true */ "./pages/periodfunctionality/index.vue");
const periodFunctionalityDetail = () => import(/* webpackPrefetch: true */ "./pages/periodfunctionality/detail.vue");
const periodFunctionalityVacation = () => import(/* webpackPrefetch: true */ "./pages/periodfunctionality/vacation.vue");
const periodFunctionalityMission = () => import(/* webpackPrefetch: true */ "./pages/periodfunctionality/mission.vue");

const sharedReport = () => import(/* webpackPrefetch: true */ "./pages/shared/report.vue");

const managementDashboardIndex = () => import(/* webpackPrefetch: true */ "./pages/management/dashboard/index.vue");
const managementDashboardExtraWorkReport = () => import(/* webpackPrefetch: true */ "./pages/management/dashboard/extraworkreport.vue");
const managementDashboardWorkDeficitReport = () => import(/* webpackPrefetch: true */ "./pages/management/dashboard/workdeficitreport.vue");
const managementDashboardDelayReport = () => import(/* webpackPrefetch: true */ "./pages/management/dashboard/delayreport.vue");
const managementDashboardHurryReport = () => import(/* webpackPrefetch: true */ "./pages/management/dashboard/hurryreport.vue");

const managementRequestsVacationIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/vacation/index.vue");
const managementRequestsVacationDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/vacation/detail.vue");

const managementRequestsMissionIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/mission/index.vue");

const managementRequestsMissionDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/mission/detail.vue");
const managementRequestsTeleworkingIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/teleworking/index.vue");
const managementRequestsTeleworkingDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/teleworking/detail.vue");

const managementRequestsMissionDetailIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/missionDetail/index.vue");

const managementRequestsDocumentIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/document/index.vue");
const managementRequestsDocumentDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/document/detail.vue");
const managementRequestsDocumentUpload = () => import(/* webpackPrefetch: true */ "./pages/management/requests/document/upload.vue");

const managementRequestsSpecialExtraWorkIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/specialExtraWork/index.vue");
const managementRequestsSpecialExtraWorkConfirm = () => import(/* webpackPrefetch: true */ "./pages/management/requests/specialExtraWork/confirm.vue");

const managementRequestsIoIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/io/index.vue");
const managementRequestsIoRecordsIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/ioRecords/index.vue");
const managementRequestsIoDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/io/detail.vue");

const managementRequestsCheckInIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/checkIn/index.vue");
const managementRequestsCheckInDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/checkIn/detail.vue");

const managementRequestsExtraWorkIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/extraWork/index.vue");
const managementRequestsExtraWorkDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/extraWork/detail.vue");

const managementRequestsStandByIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/standBy/index.vue");
const managementRequestsStandByDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/standBy/detail.vue");

const managementRequestsCustomIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/custom/index.vue");
const managementRequestsCustomDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/custom/detail.vue");

const managementRequestsShiftRelocationIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/shiftRelocation/index.vue");
const managementRequestsShiftRelocationDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/shiftRelocation/detail.vue");

const managementRequestsShiftSwapIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/shiftSwap/index.vue");
const managementRequestsShiftSwapDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/shiftSwap/detail.vue");

const managementRequestsWorkGroupRelocationIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/workGroupRelocation/index.vue");
const managementRequestsWorkGroupRelocationDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/workGroupRelocation/detail.vue");

const managementRequestsReportIndex = () => import(/* webpackPrefetch: true */ "./pages/management/requests/report/index.vue");
const managementRequestsReportDetail = () => import(/* webpackPrefetch: true */ "./pages/management/requests/report/detail.vue");

const managementReportsEventsIndex = () => import(/* webpackPrefetch: true */ "./pages/management/reports/events/index.vue");
const managementReportsRemainVacationIndex = () => import(/* webpackPrefetch: true */ "./pages/management/reports/remainVacation/index.vue");
const managementReportsCustomIndex = () => import(/* webpackPrefetch: true */ "./pages/management/reports/custom/index.vue");
const managementReportsCustomFilter = () => import(/* webpackPrefetch: true */ "./pages/management/reports/custom/filter.vue");
const managementReportsIoInIntervalIndex = () => import(/* webpackPrefetch: true */ "./pages/management/reports/ioInInterval/index.vue");
const expiredRefusedRequestIndex = () => import(/* webpackPrefetch: true */ "./pages/expiredRefusedRequestArchive/index.vue");
const managementReportsIoInDayIndex = () => import(/* webpackPrefetch: true */ "./pages/management/reports/ioInDay/index.vue");
const managementReportsSumDailyFunctionalityIndex = () => import(/* webpackPrefetch: true */ "./pages/management/reports/sumDailyFunctionality/index.vue");
const managementReportsSumPeriodFunctionalityIndex = () => import(/* webpackPrefetch: true */ "./pages/management/reports/sumPeriodFunctionality/index.vue");
const managementReportsRequestsIndex = () => import(/* webpackPrefetch: true */ "./pages/management/reports/requests/index.vue");

const managementReportsAttendanceStatusOfEmployeesIndex = () => import(/* webpackPrefetch: true */ "./pages/management/reports/attendanceStatusOfEmployees/index.vue");
const fishIndex = () => import(/* webpackPrefetch: true */ "./pages/fish/index.vue");

const reportDailyFunctionality = () => import(/* webpackPrefetch: true */ "./pages/report/dailyfunctionality.vue");
const reportPeriodFunctionality = () => import(/* webpackPrefetch: true */ "./pages/report/periodfunctionality.vue");

const reportCustomIndex = () => import(/* webpackPrefetch: true */ "./pages/report/custom/index.vue");
const reportCustomFilter = () => import(/* webpackPrefetch: true */ "./pages/report/custom/filter.vue");
const reportCustomRegisterIndex = () => import(/* webpackPrefetch: true */ "./pages/report/custom/register/index.vue");
const reportCustomRegisterFilter = () => import(/* webpackPrefetch: true */ "./pages/report/custom/register/filter.vue");
const reportCustomRegisterArchive = () => import(/* webpackPrefetch: true */ "./pages/report/custom/register/archive.vue");
const reportCustomRegisterDetail = () => import(/* webpackPrefetch: true */ "./pages/report/custom/register/detail.vue");

const smartRequestRegister = () => import(/* webpackPrefetch: true */ "./pages/smartRequest/register.vue");
const employeeAlternativeRegister = () => import(/* webpackPrefetch: true */ "./pages/employeeAlternative/register.vue");

const checkInRegister = () => import(/* webpackPrefetch: true */ "./pages/checkin/register.vue");
const checkInArchive = () => import(/* webpackPrefetch: true */ "./pages/checkin/archive.vue");
const checkInDetail = () => import(/* webpackPrefetch: true */ "./pages/checkin/detail.vue");
//const RemoteLoginWithPersonalCode = () => import(/* webpackPrefetch: true */ "./pages/index/home.vue");

Vue.component('recycle-scroller', RecycleScroller);

Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.prototype.$CaptionsLibrary = window.CaptionsLibrary;
Vue.prototype.$MessagesLibrary = window.MessagesLibrary;

function populateRoutes() {
    window.routes = [
        { path: "/", component: HomeIndex, meta: { id: 111, title: window.CaptionsLibrary.get("Profile") } },

        { path: "/home/alert", component: homeAlert, meta: { id: 59, title: window.CaptionsLibrary.get("Warnings") } },
        { path: "/home/event", component: homeEvent, meta: { id: 60, title: window.CaptionsLibrary.get("Events") } },
        { path: "/home/about", component: homeAbout, meta: { id: 61, title: window.CaptionsLibrary.get("AboutApplication") } },
        { path: "/home/errorIoGps", name: "homeErrorIoGps", component: homeErrorIoGps, props: true, meta: { id: 134, title: window.CaptionsLibrary.get("Error") } },
        { path: "/account/setting", component: accountSetting, meta: { id: 131, title: window.CaptionsLibrary.get("Setting") } },
        { path: "/account/employeeChangePass", component: accountEmployeeChangePass, meta: { id: 62, title: window.CaptionsLibrary.get("ChangePassword") } },
        { path: "/evaluation/questionnaire", name: "evaluationquestionnaire", component: evaluationQuestionnaire, props: true, meta: { id: 112, access: "EvaluationQuestionnaireAnswers", title: window.CaptionsLibrary.get("Evaluation") } },
        { path: "/evaluation/uptodownquestionnairesanswers", component: evaluationUpToDownQuestionnairesAnswers, meta: { id: 113, icon: "fa fa-sitemap", access: "ViewEvaluationQuestionnaireAnswers", title: window.CaptionsLibrary.get("ViewManagersEvaluationOfEmployees") } },
        { path: "/evaluation/selfquestionnairesanswers", component: evaluationSelfQuestionnairesAnswers, meta: { id: 122, icon: "fa fa-user-check", access: "ViewEvaluationSelfQuestionnaireAnswers", title: window.CaptionsLibrary.get("ViewEmployeesEvaluationOfThemsleves") } },

        { path: "/io/register", component: ioRegister, props: (route: Route) => (route.query), meta: { id: 1, icon: "fa fa-exchange-alt", access: "RequestIo", title: window.CaptionsLibrary.get("IoRequest") } },
        { path: "/io/register", name: "ioRegister", component: ioRegister, props: true, meta: { id: 109, icon: "fa fa-exchange-alt", access: "RequestIo", title: window.CaptionsLibrary.get("IoRequest") } },
        { path: "/io/registerIo", component: ioRegisterIo, props: (route: Route) => (route.query), meta: { id: 127, icon: "fa fa-exchange-alt", access: "RequestIo", title: window.CaptionsLibrary.get("SaveIo") } },
        { path: "/io/registerIo", name: "ioRegisterIo", component: ioRegisterIo, props: true, meta: { id: 128, icon: "fa fa-exchange-alt", access: "RequestIo", title: window.CaptionsLibrary.get("SaveIo") } },

        { path: "/io/archive", component: ioArchive, meta: { id: 2, icon: "fa fa-clock", access: "iorecordarchive", title: window.CaptionsLibrary.get("IoRequestsArchive") } },
        { path: "/io/detail", name: "ioDetail", component: ioDetail, props: true, meta: { id: 110, access: "iorecordarchive", title: window.CaptionsLibrary.get("IoSheetDetail") } },
        { path: "/io/personnel", component: ioPersonnel, meta: { id: 3, icon: "fa fa-users", access: "ViewPersonnelInoputOutputs", title: window.CaptionsLibrary.get("PersonnelIo") } },
        { path: "/io/permission", component: ioPermission, meta: { id: 4, icon: "fa fa-retweet", access: "ViewExitPermissions", title: window.CaptionsLibrary.get("ExitPermissions") } },
        { path: "/io/inPeriod", component: ioInPeriod, meta: { id: 5, icon: "fa fa-redo", access: "PeriodicIo", title: window.CaptionsLibrary.get("EntryExitInPeriod") } },
        { path: "/io/inPeriod/event", name: 'ioinperiodevent', component: dailyFunctionalityEvent, props: true, meta: { id: 63, title: window.CaptionsLibrary.get("Events") } },

        { path: "/ioGps/register", component: ioGpsRegister, props: (route: Route) => (route.query), meta: { id: 57, icon: "fa fa-exchange-alt", access: "RequestIo", title: window.CaptionsLibrary.get("IoRequest") } },
        { path: "/ioGps/archive", component: ioGpsArchive, meta: { id: 58, icon: "fa fa-clock", access: "IoRecordArchive", title: window.CaptionsLibrary.get("IoRequestsArchive") } },
        { path: "/ioGps/edit", name: "ioGpsEdit", component: ioGpsEdit, props: true, meta: { id: 64, icon: "fa fa-exchange-alt", access: "RequestIo", title: window.CaptionsLibrary.get("IoRequest") } },

        { path: "/document/register", component: documentRegister, props: (route: Route) => (route.query), meta: { id: 136, icon: "fa fa-exchange-alt", access: "DocumentRegistration", title: window.CaptionsLibrary.get("DocumentRequest") } },
        { path: "/document/register", name: "documentRegister", component: documentRegister, props: true, meta: { id: 137, icon: "fa fa-exchange-alt", access: "DocumentRegistration", title: window.CaptionsLibrary.get("DocumentRequest") } },
        { path: "/document/archive", component: documentArchive, meta: { id: 138, icon: "fa fa-clock", access: "DocumentRegistrationArchive", title: window.CaptionsLibrary.get("DocumentSheetsArchive") } },
       { path: "/document/detail", name: "documentDetail", component: documentDetail, props: true, meta: { id: 139, title: window.CaptionsLibrary.get("DocumentSheetDetail") } },

        { path: "/vacation/register", component: vacationRegister, props: (route: Route) => (route.query), meta: { id: 6, icon: "fa fa-exchange-alt", access: "RequestVacation", title: window.CaptionsLibrary.get("VacationRequest") } },
        { path: "/vacation/register", name: "vacationRegister", component: vacationRegister, props: true, meta: { id: 65, icon: "fa fa-exchange-alt", access: "RequestVacation", title: window.CaptionsLibrary.get("VacationRequest") } },
        { path: "/vacation/archive", component: vacationArchive, meta: { id: 7, icon: "fa fa-clock", access: "VacationRequestArchive", title: window.CaptionsLibrary.get("VacationSheetsArchive") } },
        { path: "/vacation/detail", name: "vacationDetail", component: vacationDetail, props: true, meta: { id: 66, title: window.CaptionsLibrary.get("VacationSheetDetail") } },
        { path: "/vacation/alternateCartable", component: vacationAlternateCartable, meta: { id: 8, icon: "fa fa-list-ol", access: null, title: window.CaptionsLibrary.get("VacationAlternateCartable") } },

        { path: "/mission/register", component: missionRegister, props: (route: Route) => (route.query), meta: { id: 9, icon: "fa fa-exchange-alt", access: "RequestMission", title: window.CaptionsLibrary.get("MissionRequest") } },
        { path: "/mission/register", name: "missionRegister", component: missionRegister, props: true, meta: { id: 67, icon: "fa fa-exchange-alt", access: "RequestMission", title: window.CaptionsLibrary.get("MissionRequest") } },
        { path: "/mission/archive", component: missionArchive, meta: { id: 10, icon: "fa fa-clock", access: "MissionRequestArchive", title: window.CaptionsLibrary.get("MissionSheetsArchive") } },
        { path: "/mission/cartable", component: missionCartable, meta: { id: 11, icon: "fa fa-list-ol", access: "MissionCartable", title: window.CaptionsLibrary.get("MissionCartable") } },
        { path: "/mission/cost", name: 'missioncost', component: missionCost, props: true, meta: { id: 68, title: window.CaptionsLibrary.get("MissionCost") } },
        { path: "/mission/detail", name: "missionDetail", component: missionDetail, props: true, meta: { id: 69, title: window.CaptionsLibrary.get("MissionSheetDetail") } },
        { path: "/mission/editDetail", component: editMissionDetail, props: true, meta: { id: 126, title: window.CaptionsLibrary.get("MissionSheetDetail") } },
        { path: "/mission/missionDetail", component: missionCartableDetail, props: true, meta: { id: 70, title: window.CaptionsLibrary.get("MissionSheetDetail") } },
        { path: "/mission/confirmed", component: missionConfirmed, meta: { id: 12, icon: "fa fa-briefcase", access: "ViewConfirmedMissions", title: window.CaptionsLibrary.get("ConfirmedMissions") } },

        { path: "/missionDetail/archive", component: missionDetailArchive, meta: { id: 13, icon: "fa fa-clock", access: "MissionArchiveDetail", title: window.CaptionsLibrary.get("MissionArchiveDetail") } },

        { path: "/extraWork/register", component: extraWorkRegister, props: (route: Route) => (route.query), meta: { id: 14, icon: "fa fa-exchange-alt", access: "RequestDailyPermission", title: window.CaptionsLibrary.get("ExtraWorkRequet") } },
        { path: "/extraWork/register", name: "extraWorkRegister", component: extraWorkRegister, props: true, meta: { id: 71, icon: "fa fa-exchange-alt", access: "RequestDailyPermission", title: window.CaptionsLibrary.get("ExtraWorkRequet") } },
        { path: "/extraWork/archive", component: extraWorkArchive, meta: { id: 15, icon: "fa fa-clock", access: "DailyPermissionRequestArchive", title: window.CaptionsLibrary.get("ExtraWorkSheetsArchive") } },
        { path: "/extraWork/detail", name: "extraWorkDetail", component: extraWorkDetail, props: true, meta: { id: 72, title: window.CaptionsLibrary.get("ExtraWorkSheetDetail") } },

        { path: "/specialExtraWork/register", component: specialExtraWorkRegister, meta: { id: 16, icon: "fa fa-exchange-alt", access: "RegisterSpecialExtrawork", title: window.CaptionsLibrary.get("RegisterSpecialExtrawork") } },
        { path: "/specialExtraWork/archive", component: specialExtraWorkArchive, meta: { id: 17, icon: "fa fa-clock", access: "RegisterSpecialExtraworkArchive", title: window.CaptionsLibrary.get("EmployeeSpecialExtraWorkArchive") } },
        { path: "/specialExtraWork/detail", name: "specialExtraWorkDetail", component: specialExtraWorkDetail, props: true, meta: { id: 73, title: window.CaptionsLibrary.get("SpecialExtraWorkSheetDetail") } },

        { path: "/standBy/register", component: standByRegister, props: (route: Route) => ({ date: route.query.date }), meta: { id: 18, icon: "fa fa-exchange-alt", access: "RequestStandbyPermission", title: `${window.CaptionsLibrary.get("Request")} ${window.CaptionsLibrary.get("Standby")}` } },
        { path: "/standBy/register", name: "standByRegister", component: standByRegister, props: true, meta: { id: 74, icon: "fa fa-exchange-alt", access: "RequestStandbyPermission", title: `${window.CaptionsLibrary.get("Request")} ${window.CaptionsLibrary.get("Standby")}` } },
        { path: "/standBy/archive", component: standByArchive, meta: { id: 19, icon: "fa fa-clock", access: "StandbyPermissionRequestArchive", title: `${window.CaptionsLibrary.get("Archive")} ${window.CaptionsLibrary.get("StandbyPapers")}` } },
        { path: "/standBy/detail", name: "standByDetail", component: standByDetail, props: true, meta: { id: 75, title: window.CaptionsLibrary.get("StandbySheetDetail") } },

        { path: "/workGroupRelocation/register", component: workGroupRelocationRegister, meta: { id: 20, icon: "fa fa-exchange-alt", access: "WorkgroupRelocationRequest", title: `${window.CaptionsLibrary.get("Request")} ${window.CaptionsLibrary.get("WorkGroupRelocation")}` } },
        { path: "/workGroupRelocation/register", name: "workGroupRelocationRegister", component: workGroupRelocationRegister, props: true, meta: { id: 76, icon: "fa fa-exchange-alt", access: "WorkgroupRelocationRequest", title: `${window.CaptionsLibrary.get("Request")} ${window.CaptionsLibrary.get("WorkGroupRelocation")}` } },
        { path: "/workGroupRelocation/archive", component: workGroupRelocationArchive, meta: { id: 21, icon: "fa fa-clock", access: "WorkgroupRelocationRequestArchive", title: window.CaptionsLibrary.get("WorkGroupRelocationSheetArchive") } },
        { path: "/workGroupRelocation/detail", name: "workGroupRelocationDetail", component: workGroupRelocationDetail, props: true, meta: { id: 77, title: window.CaptionsLibrary.get("WorkGroupRelocationSheetDetail") } },

        { path: "/shiftSwap/register", component: shiftSwapRegister, meta: { id: 22, icon: "fa fa-exchange-alt", access: "ShiftSwapRequest", title: window.CaptionsLibrary.get("SwapShiftRequest") } },
        { path: "/shiftSwap/register", name: "shiftSwapRegister", component: shiftSwapRegister, props: true, meta: { id: 78, icon: "fa fa-exchange-alt", access: "ShiftSwapRequest", title: window.CaptionsLibrary.get("SwapShiftRequest") } },
        { path: "/shiftSwap/archive", component: shiftSwapArchive, meta: { id: 23, icon: "fa fa-clock", access: "ShiftSwapRequestArchive", title: window.CaptionsLibrary.get("SwapShiftPaperArchive") } },
        { path: "/shiftSwap/detail", name: "shiftSwapDetail", component: shiftSwapDetail, props: true, meta: { id: 79, title: window.CaptionsLibrary.get("ShiftSwapSheetDetail") } },
        { path: "/shiftSwap/cartable", component: shiftSwapCartable, meta: { id: 24, icon: "fa fa-list-ol", access: null, title: window.CaptionsLibrary.get("ShiftSwapCartable") } },

        { path: "/shiftRelocation/register", component: shiftRelocationRegister, meta: { id: 25, icon: "fa fa-exchange-alt", access: "ShiftRelocationRequest", title: window.CaptionsLibrary.get("ShiftRelocationRequest") } },
        { path: "/shiftRelocation/register", name: "shiftRelocationRegister", component: shiftRelocationRegister, props: true, meta: { id: 80, icon: "fa fa-exchange-alt", access: "ShiftRelocationRequest", title: window.CaptionsLibrary.get("ShiftRelocationRequest") } },
        { path: "/shiftRelocation/archive", component: shiftRelocationArchive, props: true, meta: { id: 26, icon: "fa fa-clock", access: "ShiftRelocationRequestArchive", title: window.CaptionsLibrary.get("ShiftRelocationPermissionPagesArchive") } },
        { path: "/shiftRelocation/detail", name: "shiftRelocationDetail", component: shiftRelocationDetail, props: true, meta: { id: 81, title: window.CaptionsLibrary.get("ShiftRelocationSheetDetail") } },

        { path: "/custom/register", component: customRegister, meta: { id: 27, icon: "fa fa-exchange-alt", access: "CustomFunctinalityDetailRegistration", title: window.CaptionsLibrary.get("CustomRequest") } },
        { path: "/custom/register", name: "customRegister", component: customRegister, props: true, meta: { id: 82, icon: "fa fa-exchange-alt", access: "CustomFunctinalityDetailRegistration", title: window.CaptionsLibrary.get("CustomRequest") } },
        { path: "/custom/archive", component: customArchive, meta: { id: 28, icon: "fa fa-clock", access: "CustomFunctinalityDetailRegistrationRequestArchive", title: window.CaptionsLibrary.get("CustomFunctionalityPermissionPagesArchive") } },

        { path: "/custom/detail", name: "customDetail", component: customDetail, props: true, meta: { id: 83, title: window.CaptionsLibrary.get("CustomSheetDetail") } },

        { path: "/shared/workFlow", name: "sharedWorkFlow", component: sharedWorkFlow, props: true, meta: { id: 84, title: window.CaptionsLibrary.get("WorkFlow") } },
        { path: "/account/login", component: AccountLogin, meta: { id: 85, title: window.CaptionsLibrary.get("Login") } },
        { path: "/account/changePass", component: AccountChangePass, meta: { id: 86, title: window.CaptionsLibrary.get("ChangePassword") } },

        { path: "/dailyfunctionality", component: dailyFunctionalityIndex, meta: { id: 29, icon: "fa fa-clock", access: "DailyFunctionality", title: window.CaptionsLibrary.get("DailyFunctionTitle") } },
        { path: "/dailyfunctionality/detail", name: 'dailyfunctionalitydetail', component: dailyFunctionalityDetail, props: true, meta: { id: 87, title: window.CaptionsLibrary.get("DailyFunctionalityDetail") } },
        { path: "/dailyfunctionality/event", name: 'dailyfunctionalityevent', component: dailyFunctionalityEvent, props: true, meta: { id: 88, title: window.CaptionsLibrary.get("Events") } },

        { path: "/periodfunctionality", component: periodFunctionalityIndex, meta: { id: 30, icon: "fa fa-calendar", access: "PeriodFunctionality", title: window.CaptionsLibrary.get("PeriodicFunction") } },
        { path: "/periodfunctionality/detail", name: "periodfunctionalitydetail", component: periodFunctionalityDetail, props: true, meta: { id: 89, title: window.CaptionsLibrary.get("PeriodFunctionalityDetail") } },
        { path: "/periodfunctionality/vacation", name: "periodfunctionalityvacation", component: periodFunctionalityVacation, props: true, meta: { id: 90, title: window.CaptionsLibrary.get("Vacations") } },
        { path: "/periodfunctionality/mission", name: "periodfunctionalitymission", component: periodFunctionalityMission, props: true, meta: { id: 91, title: window.CaptionsLibrary.get("Missions") } },

        { path: "/shared/report", name: "report", component: sharedReport, props: true, meta: { id: 92, title: window.CaptionsLibrary.get("Report") } },

        { path: "/management/dashboard", component: managementDashboardIndex, meta: { id: 114, icon: "fa fa-tachometer-alt", access: null, title: window.CaptionsLibrary.get("ManagementDashboard") } },
        { path: "/management/dashboard/extraworkreport", component: managementDashboardExtraWorkReport, meta: { id: 115, icon: "fa fa-pie-chart", access: null, title: window.CaptionsLibrary.get("ManagementDashboard") } },
        { path: "/management/dashboard/workdeficitreport", component: managementDashboardWorkDeficitReport, meta: { id: 116, icon: "fa fa-pie-chart", access: null, title: window.CaptionsLibrary.get("ManagementDashboard") } },
        { path: "/management/dashboard/delayreport", component: managementDashboardDelayReport, meta: { id: 117, icon: "fa fa-pie-chart", access: null, title: window.CaptionsLibrary.get("ManagementDashboard") } },
        { path: "/management/dashboard/hurryreport", component: managementDashboardHurryReport, meta: { id: 118, icon: "fa fa-pie-chart", access: null, title: window.CaptionsLibrary.get("ManagementDashboard") } },

        { path: "/management/requests/vacation/Index", component: managementRequestsVacationIndex, meta: { id: 31, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("VacationRequests") } },
        { path: "/management/requests/vacation/detail/:requestId?", name: "managementrequestsvacationdetail", component: managementRequestsVacationDetail, props: true, meta: { id: 93, title: window.CaptionsLibrary.get("VacationSheetDetail") } },

        { path: "/management/requests/mission/Index", component: managementRequestsMissionIndex, meta: { id: 32, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("MissionRequests") } },
        { path: "/management/requests/mission/detail/:requestId?", name: "managementrequestsmissiondetail", component: managementRequestsMissionDetail, props: true, meta: { id: 94, title: window.CaptionsLibrary.get("MissionSheetDetail") } },

        { path: "/management/requests/teleworking/Index", component: managementRequestsTeleworkingIndex, meta: { id: 129, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("TeleworkingRequests") } },
        { path: "/management/requests/teleworking/detail/:requestId?", name: "managementrequeststeleworkingdetail", component: managementRequestsTeleworkingDetail, props: true, meta: { id: 130, title: window.CaptionsLibrary.get("TeleworkingDetail") } },

        { path: "/management/requests/missionDetail/Index", component: managementRequestsMissionDetailIndex, meta: { id: 33, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("MissionDetailRequests") } },

        { path: "/management/requests/document/Index", component: managementRequestsDocumentIndex, meta: { id: 140, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("DocumentRequests") } },
        { path: "/management/requests/document/detail/:requestId?", name: "managementrequestsdocumentdetail", component: managementRequestsDocumentDetail, props: true, meta: { id: 141, title: window.CaptionsLibrary.get("DocumentSheetDetail") } },
        { path: "/management/requests/document/upload/:requestId?", name: "managementrequestsdocumentupload", component: managementRequestsDocumentUpload, props: true, meta: { id: 142, title: window.CaptionsLibrary.get("DocumentSheetDetail") } },
        //{ path: "/smartrequest/register", component: smartRequestRegister, props: (route: Route) => ({ date: route.query.date }), meta: { id: 95, title: window.CaptionsLibrary.get("RegisterSmartRequest") } },

        { path: "/smartrequest/register", component: smartRequestRegister, props: (route: Route) => (route.query), meta: { id: 95, title: window.CaptionsLibrary.get("RegisterSmartRequest") } },
        { path: "/smartrequest/register", name: "smartRequestRegister", component: smartRequestRegister, props: true, meta: { id: 133, title: window.CaptionsLibrary.get("RegisterSmartRequest") } },

        { path: "/management/requests/specialExtraWork/Index", component: managementRequestsSpecialExtraWorkIndex, meta: { id: 34, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("SpecialExtraWorkRequests") } },
        { path: "/management/requests/specialExtraWork/confirm", name: "managementrequestsspecialextraworkconfirm", component: managementRequestsSpecialExtraWorkConfirm, props: true, meta: { id: 96, title: window.CaptionsLibrary.get("SpecialExtraWorkSheetDetail") } },

        { path: "/management/requests/io/Index", component: managementRequestsIoIndex, meta: { id: 35, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("IoRequests") } },
        { path: "/management/requests/ioRecords/Index", component: managementRequestsIoRecordsIndex, meta: { id: 144, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("IoRequests") } },
        { path: "/management/requests/io/detail/:requestId?", name: "managementrequestsiodetail", component: managementRequestsIoDetail, props: true, meta: { id: 96, title: window.CaptionsLibrary.get("IoSheetDetail") } },

        { path: "/management/requests/checkIn/Index", component: managementRequestsCheckInIndex, meta: { id: 123, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("CheckInRequests") } },
        { path: "/management/requests/checkIn/detail/:requestId?", name: "managementrequestscheckindetail", component: managementRequestsCheckInDetail, props: true, meta: { id: 124, title: window.CaptionsLibrary.get("CheckInSheetDetail") } },

        { path: "/management/requests/extraWork/Index", component: managementRequestsExtraWorkIndex, meta: { id: 36, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("ExtraWorkRequests") } },
        { path: "/management/requests/extrawork/detail/:requestId?", name: "managementrequestsextraworkdetail", component: managementRequestsExtraWorkDetail, props: true, meta: { id: 97, title: window.CaptionsLibrary.get("ExtraWorkSheetDetail") } },

        { path: "/management/requests/standBy/Index", component: managementRequestsStandByIndex, meta: { id: 37, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("StandbyPermissionRequests") } },
        { path: "/management/requests/standby/detail/:requestId?", name: "managementrequestsstandbydetail", component: managementRequestsStandByDetail, props: true, meta: { id: 98, title: window.CaptionsLibrary.get("StandbySheetDetail") } },

        { path: "/management/requests/custom/Index", component: managementRequestsCustomIndex, meta: { id: 38, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("CustomRequests") } },
        { path: "/management/requests/custom/detail/:requestId?", name: "managementrequestscustomdetail", component: managementRequestsCustomDetail, props: true, meta: { id: 99, title: window.CaptionsLibrary.get("CustomSheetDetail") } },

        { path: "/management/requests/shiftRelocation/Index", component: managementRequestsShiftRelocationIndex, meta: { id: 39, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("ShiftRelocationRequests") } },
        { path: "/management/requests/shiftrelocation/detail/:requestId?", name: "managementrequestsshiftrelocationdetail", component: managementRequestsShiftRelocationDetail, props: true, meta: { id: 100, title: window.CaptionsLibrary.get("ShiftRelocationSheetDetail") } },

        { path: "/management/requests/shiftSwap/Index", component: managementRequestsShiftSwapIndex, meta: { id: 40, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("ShiftSwapRequests") } },
        { path: "/management/requests/shiftswap/detail/:requestId?", name: "managementrequestsshiftswapdetail", component: managementRequestsShiftSwapDetail, props: true, meta: { id: 101, title: window.CaptionsLibrary.get("ShiftSwapSheetDetail") } },

        { path: "/management/requests/workGroupRelocation/Index", component: managementRequestsWorkGroupRelocationIndex, meta: { id: 41, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("WorkGroupRelocationRequests") } },
        { path: "/management/requests/workGroupRelocation/detail/:requestId?", name: "managementrequestsworkgrouprelocationdetail", component: managementRequestsWorkGroupRelocationDetail, props: true, meta: { id: 102, title: window.CaptionsLibrary.get("WorkGroupRelocationSheetDetail") } },

        { path: "/management/requests/report/index", component: managementRequestsReportIndex, meta: { id: 42, icon: "fa fa-tasks", access: null, title: window.CaptionsLibrary.get("ReportRequests") } },
        { path: "/management/requests/report/detail/:requestId?", name: "managementrequestsreportdetail", component: managementRequestsReportDetail, props: true, meta: { id: 103, title: window.CaptionsLibrary.get("CustomReportSheetDetail") } },

        { path: "/management/reports/events/Index", component: managementReportsEventsIndex, meta: { id: 43, icon: "fab fa-elementor", access: "EventsReport", title: window.CaptionsLibrary.get("EmployeeEventsReport") } },
        { path: "/management/reports/remainVacation/Index", component: managementReportsRemainVacationIndex, meta: { id: 44, icon: "fa fa-suitcase-rolling", access: "RemainVacationReport", title: window.CaptionsLibrary.get("RemainVacationReport") } },
        { path: "/management/reports/custom/Index", component: managementReportsCustomIndex, meta: { id: 45, icon: "fa fa-user-tag", access: "PersonnelCustomReports", title: `${window.CaptionsLibrary.get("Reports")} ${window.CaptionsLibrary.get("Custom")} ${window.CaptionsLibrary.get("Personnel")}` } },
        { path: "/management/reports/custom/Filter/:id", component: managementReportsCustomFilter, props: true, meta: { id: 104, title: `${window.CaptionsLibrary.get("Filter")} ${window.CaptionsLibrary.get("Report")}` } },
        { path: "/management/reports/ioInInterval/Index", component: managementReportsIoInIntervalIndex, meta: { id: 46, icon: "fa fa-door-open", access: "IoReport", title: `${window.CaptionsLibrary.get("Report")} ${window.CaptionsLibrary.get("IoInRange")}` } },
        { path: "/management/reports/ioInDay/Index", component: managementReportsIoInDayIndex, meta: { id: 47, icon: "fa fa-door-open", access: "IoReport", title: `${window.CaptionsLibrary.get("Report")} ${window.CaptionsLibrary.get("IoInDay")}` } },
        { path: "/management/reports/sumDailyFunctionality/Index", component: managementReportsSumDailyFunctionalityIndex, meta: { id: 48, icon: "fa fa-calendar-day", access: "SumDailyFunctionalityReport", title: window.CaptionsLibrary.get("SumDailyFunctionalityReport") } },
        { path: "/management/reports/sumPeriodFunctionality/Index", component: managementReportsSumPeriodFunctionalityIndex, meta: { id: 49, icon: "fa fa-calendar", access: "SumPeriodFunctionalityReport", title: window.CaptionsLibrary.get("SumPeriodFunctionalityReport") } },
        { path: "/management/reports/requests/Index", component: managementReportsRequestsIndex, meta: { id: 50, icon: "fa fa-newspaper", access: "RequestsReport", title: `${window.CaptionsLibrary.get("Reports")} ${window.CaptionsLibrary.get("PersonnelRequests")}` } },
        { path: "/management/reports/attendanceStatusOfEmployees/Index", component: managementReportsAttendanceStatusOfEmployeesIndex, meta: { id: 143, icon: "fa fa-time", access: "RequestsReport", title: `${window.CaptionsLibrary.get("Reports")} ${window.CaptionsLibrary.get("AttendanceStatusOfEmployees")}` } },

        { path: "/expiredRefusedRequestArchive/Index", component: expiredRefusedRequestIndex, meta: { id: 132, icon: "fa fa-door-open", access: "ShowRejectedOrExpiredRequests", title: ` ${window.CaptionsLibrary.get("ExpiredRefusedArchive")}` } },
        { path: "/fish/Index", component: fishIndex, meta: { id: 135, icon: "fa fa-list-alt", access: "ShowWinPayPayslip", title: `${window.CaptionsLibrary.get("Fish")}` } },  


        { path: "/report/dailyfunctionality", component: reportDailyFunctionality, meta: { id: 51, icon: "fa fa-calendar-day", access: "DailyFunctionalityReport", title: `${window.CaptionsLibrary.get("Reports")} ${window.CaptionsLibrary.get("DailyFunctionTitle")}` } },
        { path: "/report/periodfunctionality", component: reportPeriodFunctionality, meta: { id: 52, icon: "fa fa-calendar", access: "PeriodFunctionalityReport", title: `${window.CaptionsLibrary.get("Reports")} ${window.CaptionsLibrary.get("PeriodicFunction")}` } },

        { path: "/report/custom/index", component: reportCustomIndex, meta: { id: 53, icon: "fa fa-table", access: "PersonnelCustomReports", title: `${window.CaptionsLibrary.get("Reports")} ${window.CaptionsLibrary.get("Custom")}` } },
        { path: "/report/custom/filter/:id", component: reportCustomFilter, props: true, meta: { id: 105, title: `${window.CaptionsLibrary.get("Filter")} ${window.CaptionsLibrary.get("Custom")} ${window.CaptionsLibrary.get("Report")}` } },
        { path: "/report/custom/register", component: reportCustomRegisterIndex, meta: { id: 54, icon: "fa fa-exchange-alt", access: "RequestReport", title: window.CaptionsLibrary.get("CustomRequest") } },
        { path: "/report/custom/register/filter/:id/:forRegister", component: reportCustomRegisterFilter, props: true, meta: { id: 106, title: `${window.CaptionsLibrary.get("Filter")} ${window.CaptionsLibrary.get("Custom")} ${window.CaptionsLibrary.get("Report")}` } },
        { path: "/report/custom/register/archive", component: reportCustomRegisterArchive, meta: { id: 55, icon: "fa fa-clock", access: "ReportRequestArchive", title: window.CaptionsLibrary.get("CustomFunctionalityPermissionPagesArchive") } },
        { path: "/report/custom/register/detail", name: "reportcustomregisterDetail", component: reportCustomRegisterDetail, props: true, meta: { id: 107, title: window.CaptionsLibrary.get("CustomReportSheetDetail") } },
        { path: "/employeeAlternative/register", component: employeeAlternativeRegister, meta: { id: 56, icon: "fa fa-user-alt-slash", access: "JobPositionAlternateManagement", title: window.CaptionsLibrary.get("JobPositionAlternateManagement") } },

        { path: "/checkin/register", component: checkInRegister, meta: { id: 119, icon: "fa fa-exchange-alt", access: "CheckInRequest", title: window.CaptionsLibrary.get("RequestCheckIn") } },
        { path: "/checkin/archive", component: checkInArchive, meta: { id: 120, icon: "fa fa-clock", access: "CheckInRequestsArchive", title: window.CaptionsLibrary.get("CheckInRequestsArchive") } },
        { path: "/checkin/detail/", name: "checkInDetail", component: checkInDetail, props: true, meta: { id: 121, title: window.CaptionsLibrary.get("CheckInRecordDetail") } },
        // { path: "/account/RemoteLoginWithPersonalCode/", name: "remotLoginP", component: RemoteLoginWithPersonalCode, props: true, meta: { id: 125, title: window.CaptionsLibrary.get("RemoteLogin") } },

        { path: "*", component: HomeIndex, meta: { id: 108, title: window.CaptionsLibrary.get("Profile") } },
    ];
}

populateRoutes();
//let baseUrlMTN = false;
//checkRemote((result: any) => {

//    window.baseUrl = result;
//    baseUrlMTN = result;
//});

let router = new VueRouter({ mode: "history", routes: window.routes });
let checkRemotLogin = false;
let baseUrlMTN = false;
let baseUrlKish = false;
let baseRemote = false;
let mtnRemoteUrl = false;
let count = 0;


router.beforeResolve(async (to, from, next) => {
    if (baseRemote) {
        var tokensul = localStorage.getItem("sulLoginCount");
        var id_token = localStorage.getItem("id_token");
        //var id_token = TokenId;
        if (id_token == "" && tokensul == null) {
            localStorage.setItem("sulLoginCount", "1");
         // window.location.replace("http://sul.mtnirancell.ir/authorization?response_type=code&scope=openid+sub+email+name+preferred_username+phone_number&client_id=7MrIf7Q0ZEC4&redirect_uri=http://10.131.59.137/Account/SulRemoteLogin&state=af0ifjsldkj&nonce=n-0S6_WzA2Mj")
           window.location.replace("http://sul.mtnirancell.ir/authorization?response_type=code&scope=openid+profile+email&client_id=7MrIf7Q0ZEC4&redirect_uri=http://10.131.59.137/api/Account/SulRemoteLogin&state=af0ifjsldkj&nonce=n-0S6_WzA2Mj")
            //window.location.replace("http://localhost:1401/api/Account/SulRemoteLogin?state=af0ifjsldkj&code=5a677c32d8be48cdaa67a6073af31dc2");

        }
    }

    // if (to.path == "/api/account/RemoteLoginWithPersonalCode" && from.path == "/" ) {
    //     
    //        }
    if (to.fullPath == "/account/login") {
        checkLogin();
        if (checkRemotLogin == false && baseUrlMTN == false && baseUrlKish == false && mtnRemoteUrl==false) {
            next();

        } else {

            router.push("/");
            return;
        }


    }
    if (to.path == "/api/account/RemoteLoginWithPersonalCode"/*&& to.path!="/"*/) {
        sessionStorage.clear();
        Vue.prototype.$UserInfo = {};
        delete window.access;
        RemoteLoginWithPersonalCode(to.fullPath);
        router.push("/");
        return;

    }
    if (to.path == "/api/account/RemoteLoginWithToken") {
        sessionStorage.clear();
        Vue.prototype.$UserInfo = {};
        delete window.access;
        RemoteLoginWithToken(to.fullPath);
        router.push("/");
        return;

    }
    //if (to.path == "/api/account/SsoRemoteLogin") {
    //    debugger;
    //    sessionStorage.clear();
    //    Vue.prototype.$UserInfo = {};
    //    delete window.access;
    //    SsoRemoteLogin(to.fullPath,to.params);
    //    router.push("/");
    //    return;

    //}
    if (to.path == "/api/Account/SulRemoteLogin"/*&& to.path!="/"*/) {
        sessionStorage.clear();
        Vue.prototype.$UserInfo = {};
        delete window.access;
        RemoteLoginMTN(to.fullPath);
        router.push("/");
        return;

    }
    //if (to.path == "/api/account/SULLogout") {
    //    debugger;
    //    RemoteLogoutMTN(to.fullPath);
    //    return;
    //}
    if (to.path == "/api/account/SsoHasUser") {
        ssoHasUser(to.fullPath);
        return;
    }
    debugger;
    if (to.path == "/api/DocumentRequests/GetParkbanManagerMobileAppFile") {
        debugger;
        fetch(to.fullPath);
       // next();
        return;
    }
    if (to.path.toLowerCase() == "/Account/SulRemoteLogin".toLowerCase()) {
        sessionStorage.clear();
        Vue.prototype.$UserInfo = {};
        delete window.access;
        RemoteLoginMTN("/api" + to.fullPath);
        // RemoteLoginWithMtn(to.fullPath);
        //RemoteLoginMtn(to.fullPath);
        // rLoginMTN = true;
        router.push("/");
        return;


    }
    sessionExpired(to, from, next);

});
function checkLogin() {
    window.app.$emit(EventType.StartWaiting);
    $.ajax({
        type: "POST",
        url: "/api/Account/GetRemoteUrlSinaMTN",
        dataType: "json",
        success: result => {
            baseUrlMTN = result.baseUrlMTN;
            baseUrlKish = result.baseUrlKish;
            checkRemotLogin = result.exitButtonVisibility;

        },
        complete: () => {
            window.app.$emit(EventType.EndWaiting);
            //},
            //error: (jqXHR, status) => {
            //    checkRemotLogin = false;
        }
    });
}
function sessionExpired(to: any, from: any, next: any) {
    let ExitButtonVisibility = false;
    let baseUrlMTN = false;
    let mtnRemoteUrl = false;
    $.ajax({
        type: "POST",
        url: "/api/Account/GetRemoteUrlSinaMTN",
        dataType: "json",
        success: result => {
            baseUrlMTN = result.baseUrlMTN;
            ExitButtonVisibility = result.exitButtonVisibility;
            baseUrlKish = result.baseUrlKish;
            mtnRemoteUrl = result.mTNRemoteLogin
        }
        ,
        complete: () => {
            window.app.$emit(EventType.EndWaiting);
        }
    });
    if (window.offlineMode && (to.fullPath.includes("ioGps") || to.fullPath == "/")) {       
        next();
        return;
    }


    function login() {
        const username = localStorage.getItem("this");
        const password = localStorage.getItem("that");
        const loginType = localStorage.getItem("loginType");

        if ((!username || !password)) {
            if (ExitButtonVisibility == false && baseUrlMTN == false && baseUrlKish == false && mtnRemoteUrl==false) {
                router.push("/account/login");
                return;
            }

        }

        window.app.$emit(EventType.StartWaiting);
        $.ajax({
            type: "POST",
            url: "/api/Account/Login",
            data: {
                username,
                password,
                loginType,
                isFromMobile: window.orientation !== undefined,
                isFromLocalStorage: true,
            },
            dataType: "json",
            success: result => {
                if (result != null) {
                    if (result.ResponseType === ResponseType.Ok) {
                        if (result.Data !== null) {
                            if (result.Data.ExpiredPass) {
                                const to = "/account/changePass";
                                router.push(to);
                                localStorage.setItem("pageLock", to);
                                return;
                            } else if (result.Data.Questionnaires && result.Data.Questionnaires.length && result.Data.Questionnaires != "_Logon_") {
                                const to = "evaluationquestionnaire";
                                router.push({ name: to, params: { model: result.Data.Questionnaires } });
                                localStorage.setItem("pageLock", to);
                                return;
                            }
                            if (result.Data.Token) {
                                debugger;
                                localStorage.setItem("Token", result.Data.Token);
                                $.ajaxSetup({
                                    headers: { "Token": localStorage.getItem("Token") },
                                    statusCode: {
                                        401: function () {
                                            //مشکل ساز در سوئیچ افلاین
                                          //  window.localStorage.clear();
                                            location.reload();
                                        }
                                    }
                                });
                            }
                        }

                        localStorage.removeItem("pageLock");
                        checkAccess(to, from, next);
                        return;
                    }
                    ////@ts-ignore
                    //this.$root.$children[0].popupNotificationWidget.show(result.Message, getNotificationType(result.ResponseType));
                    if (ExitButtonVisibility == false && baseUrlMTN == false && baseUrlKish == false && mtnRemoteUrl==false) {
                        router.push("/account/login");
                    }
                }
            },
            complete: () => {
                window.app.$emit(EventType.EndWaiting);
            }
        });
    }

    function shouldBeLogin() {
        window.app.$emit(EventType.StartWaiting);
        $.ajax({
            type: "POST",
            url: "/api/Account/ShouldBeLogin",
            dataType: "json",
            data: {
                isFromMobile: window.orientation !== undefined,
            },
            success: result => {
                if (result != null) {
                    if (result === ResponseType.Failed) {
                        login();
                        return;
                    }
                    checkAccess(to, from, next);
                }
            },
            complete: () => {
                window.app.$emit(EventType.EndWaiting);
            }
        });
    }


    window.app.$emit(EventType.StartWaiting);
    $.ajax({
        type: "POST",
        url: "/api/Account/SessionExpired",
        dataType: "json",
        success: result => {
            debugger;

            //if (to.path != "/api/account/RemoteLoginWithPersonalCode"/*&& to.path!="/"*/) {
            //    //router.push("/account/login");
            //} else {
            //    sessionStorage.clear();
            //    Vue.prototype.$UserInfo = {};
            //    delete window.access;
            //    RemoteLoginWithPersonalCode(to.fullPath);
            //    debugger;
            //   // next();
            //// router.push("/");
            // 
            //    return;
            //}
            if (result) {
                const offlineModeEnabled = localStorage.getItem("enableOfflineMode") != null;
                if (offlineModeEnabled) {
                    debugger;
                    shouldBeLogin();
                    return;
                } else {
                    if (ExitButtonVisibility != true && baseUrlMTN != true && baseUrlKish != true && mtnRemoteUrl!=true) {
                        sessionStorage.clear();
                        debugger;
                        router.push("/account/login");
                    }
                }

            }
            debugger;
            checkAccess(to, from, next);

        },
        error: () => {
            sessionStorage.clear();

            //router.push("/account/login");
        },
        complete: () => {
            window.app.$emit(EventType.EndWaiting);
        }
    });
    function deleteAllCookie() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
}

function RemoteLoginWithToken(url: string) {
    debugger;
    var isMobile = window.orientation !== undefined;
    var newurl = url + "&isMobile=" + isMobile;
    window.app.$emit(EventType.StartWaiting);
    $.ajax({
        type: "Get",
        url: newurl,
        data: {

        },
        dataType: "json",
        success: result => {
            if (result != null) {
                debugger;
                if (result.ResponseType === ResponseType.Ok) {

                    if (result.Data.Questionnaires && result.Data.Questionnaires.length && result.Data.Questionnaires != "_Logon_") {
                        const to = "evaluationquestionnaire";
                        router.push({ name: to, params: { model: result.Data.Questionnaires } });
                        localStorage.setItem("pageLock", to);

                    }

                    return;
                } else {
                    sessionStorage.clear();
                    Vue.prototype.$UserInfo = {};
                   
                }


            }
        },
        error: function (jqXHR, exception) {

        },
        complete: () => {
            window.app.$emit(EventType.EndWaiting);
        }

    });
}
function RemoteLoginWithPersonalCode(url: string) {
    debugger;
    var isMobile = window.orientation !== undefined;
    var newurl = url + "&isMobile=" + isMobile;
    window.app.$emit(EventType.StartWaiting);
    $.ajax({
        type: "Get",
        url: newurl,
        data: {

        },
        dataType: "json",
        success: result => {
            if (result != null) {
                debugger;
                if (result.ResponseType === ResponseType.Ok) {
                    if (result.Data.Questionnaires && result.Data.Questionnaires.length && result.Data.Questionnaires != "_Logon_") {
                        const to = "evaluationquestionnaire";
                        router.push({ name: to, params: { model: result.Data.Questionnaires } });
                        localStorage.setItem("pageLock", to);

                    }

                    return;
                } else {
                    sessionStorage.clear();
                    Vue.prototype.$UserInfo = {};
                    //delete window.access;
                    //window.close();

                    ////@ts-ignore
                    // this.$root.$children[0].popupNotificationWidget.show(result.Message, getNotificationType(result.ResponseType));

                }


                //router.push("/account/login");

            }
        },
        error: function (jqXHR, exception) {

        },
        complete: () => {
            window.app.$emit(EventType.EndWaiting);
        }

    });
}
function SsoRemoteLogin(url: string , data:any) {
    debugger;
    var isMobile = window.orientation !== undefined;
    var newurl = url + "&isMobile=" + isMobile;
    
    window.app.$emit(EventType.StartWaiting);
    $.ajax({
        type: "POST",
        url: url,
        data: {

        },
        dataType: "json",
        success: result => {
            if (result != null) {
                debugger;
                if (result.ResponseType === ResponseType.Ok) {
                    if (result.Data.Questionnaires && result.Data.Questionnaires.length && result.Data.Questionnaires != "_Logon_") {
                        const to = "evaluationquestionnaire";
                        router.push({ name: to, params: { model: result.Data.Questionnaires } });
                        localStorage.setItem("pageLock", to);

                    }

                    return;
                } else {
                    sessionStorage.clear();
                    Vue.prototype.$UserInfo = {};
                    //delete window.access;
                    //window.close();

                    ////@ts-ignore
                    // this.$root.$children[0].popupNotificationWidget.show(result.Message, getNotificationType(result.ResponseType));

                }


                //router.push("/account/login");

            }
        },
        error: function (jqXHR, exception) {

        },
        complete: () => {
            window.app.$emit(EventType.EndWaiting);
        }

    });
}
function RemoteLoginMTN(url: string) {
    var isMobile = window.orientation !== undefined;
    var newurl = url + "&isMobile=" + isMobile;
    window.app.$emit(EventType.StartWaiting);
    $.ajax({
        type: "Get",
        url: newurl,
        data: {

        },
        dataType: "json",
        success: result => {
            if (result != null) {
                debugger;
                if (result.ResponseType === ResponseType.Ok) {
                    debugger;
                    if (result.Data.Questionnaires && result.Data.Questionnaires.length && result.Data.Questionnaires != "_Logon_") {
                        const to = "evaluationquestionnaire";
                        router.push({ name: to, params: { model: result.Data.Questionnaires } });
                        localStorage.setItem("pageLock", to);

                    }
                    if (result.Data.token_id != "") {
                        localStorage.setItem("id_token", result.Data.token_id);
                    }

                    return;
                } else {
                    sessionStorage.clear();
                    Vue.prototype.$UserInfo = {};
                    //delete window.access;
                    //window.close();

                    ////@ts-ignore
                    // this.$root.$children[0].popupNotificationWidget.show(result.Message, getNotificationType(result.ResponseType));

                }


                //router.push("/account/login");

            }
        },
        complete: () => {
            window.app.$emit(EventType.EndWaiting);
        },
        error: function (jqXHR, exception) {

        },

    });
}

function ssoHasUser(url: string) {
    
    window.app.$emit(EventType.StartWaiting);
    $.ajax({
        type: "Post",
        url: url,
        data: {

        },
        dataType: "json",
        success: result => {
            if (result != null) {
                debugger;
                if (result.ResponseType === ResponseType.Ok) {
                    debugger;
                   

                   
                } else {
                 
                }


                

            }
        },
        complete: () => {
            window.app.$emit(EventType.EndWaiting);
        },
        error: function (jqXHR, exception) {

        },

    });
}
function pad(num: number) {
    if (num < 10) {
        return '0' + num;
    }
    return num;
}

Date.prototype.toJSON = function () {
    return this.getFullYear() +
        '-' + pad(this.getMonth() + 1) +
        '-' + pad(this.getDate()) +
        'T' + pad(this.getHours()) +
        ':' + pad(this.getMinutes()) +
        ':' + pad(this.getSeconds()) +
        '.' + (this.getMilliseconds() / 1000).toFixed(3).slice(2, 5);
};

Vue.prototype.$UserInfo = {

};

window.offlineMode = localStorage.getItem("offlineMode") != null && window.orientation !== undefined;

webViewTypeItems((result: any) => {
    window.webViewTypes = result;
    Vue.prototype.$webViewTypes = window.webViewTypes;
});

if (baseRemote == false && count == 0) {
    $.ajax({
        type: "POST",
        url: "/api/Account/GetRemoteUrlSinaMTN",
        dataType: "json",
        success: result => {
            debugger;
            baseRemote = result.baseUrlMTN;
            baseUrlKish = result.baseUrlKish;
            mtnRemoteUrl = result.mTNRemoteLogin;
            localStorage.setItem("id_token", result.id_token);
            count = 1;

        }
    });
}
let TokenId = "";
function GetTokenId() {
    window.app.$emit(EventType.StartWaiting);
    $.ajax({
        type: "POST",
        url: "/api/Account/GetTokenId",
        dataType: "json",
        success: result => {
            debugger;
            TokenId = result;
            localStorage.setItem("id_token", result.id_token);
        },
        complete: () => {
            window.app.$emit(EventType.EndWaiting);
        }
    });
}

let cultureResult = false;
$.ajax({
    type: "GET",
    url: "/api/Culture/GetSelectedCulture",
    dataType: "json",
    success: result => {
        cultureResult = result;
    },
    complete: () => {
        const culture = cultureResult ? "en-US" : "fa-IR";
        document.documentElement.lang = culture;
        window.CaptionsLibrary._culture = culture;
        window.MessagesLibrary._culture = culture;
        if (cultureResult) {
            document.body.classList.remove("k-rtl");
            document.body.dir = "ltr";
            document.body.style.fontFamily = "tahoma";
        }
        populateRoutes();
        window.app = new Vue({
            el: "#app-root",
            router,
            components: {
                "app-main": AppMain
            },
        });
    }
});

//window.onbeforeunload = function () {
//   // s2
//   //sessionStorage.clear();
//    //s3
//   //deleteAllCookies();

//    //s4
//    //localStorage.clear();

//};
function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
function cleanup() {
    window.sessionStorage.clear();
      //مشکل ساز در سوئیچ افلاین
   // window.localStorage.clear();
    deleteAllCookies();

}
function tabOpened() {
    const tabs = window.localStorage.getItem('tabs')
    if (tabs === null) {

        window.localStorage.setItem('tabs', '1')
    } else {
        var t = Number(tabs) + 1;
        window.localStorage.setItem('tabs', "" + t + "")
    }
}

function tabClosed() {
    const tabs = window.localStorage.getItem('tabs')

    if (Number(tabs) <= 1) {
        // last tab closed, perform cleanup.
        window.localStorage.removeItem('tabs')
        cleanup()
    } else {
        var t = Number(tabs) - 1;
        window.localStorage.setItem('tabs', "" + t + "")
    }
}
////s5
//window.onload = function () {
//    tabOpened();
//}
////s5
//window.onbeforeunload = function () {

//    tabClosed();
//    ////document.cookie = "status=false";

//}


//@ts-ignore
window.minToHourMin = (input: any, zeroPadding: any, hourLength: any) => {
    var isMinus = input < 0;
    input = Math.abs(input);
    hourLength = hourLength || 3;
    var hour = Math.floor(input / 60);
    var min = Math.abs(input) % 60;
    var result = "";

    if (zeroPadding === true) {
        result = String("000" + hour).slice(-hourLength) + ":" + String("00" + min).slice(-2);
    } else {
        result = hour + ":" + String("00" + min).slice(-2);
    }

    if (isMinus) { result = "-" + result; }

    return result;
}

declare global {
    interface Window {
        app: any;
        CaptionsLibrary: any;
        MessagesLibrary: any;
        UserInfo: any;
        webViewTypes: any;
        routes: Array<RouteConfig>;
        access: Array<number>;
        offlineMode: boolean;
        tileUrl: string;
        ResizeObserver: any;
    }
}

declare module "vue/types/vue" {
    interface Vue {
        $CaptionsLibrary: { get: Function },
        $MessagesLibrary: { get: Function },
        $UserInfo: any,
    }
}

window.addEventListener("load", () => {
    if ('serviceWorker' in navigator) {
        OfflinePluginRuntime.install();
        navigator.serviceWorker.ready.then((registration) => {
            registration.onupdatefound = () => {
                const installing = registration.installing!;
                installing.onstatechange = () => {
                    installing.state == "installed" && window.location.reload();
                };
            }
            return registration.update();
        });
    }
});

function removeCustomValidity(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target) {
        target.setCustomValidity("");
        if (!target.checkValidity()) {
            const customValidity = generateCustomValidity(target);
            target.setCustomValidity(customValidity);
            target.addEventListener("input", removeCustomValidity, { once: true });
        }
    }
}

document.addEventListener('invalid', (() => (e: Event) => {
    const target = e.target as HTMLInputElement;
    if (target) {
        const customValidity = generateCustomValidity(target);
        target.setCustomValidity(customValidity);
        target.addEventListener("input", removeCustomValidity, { once: true });
    }
})(), true);

function checkAccess(to: any, from: any, next: any) {
    const pageLock = localStorage.getItem("pageLock");
    if (pageLock) {
        if (pageLock == to.path || pageLock == to.name) next();
        else if (pageLock == from.path || pageLock == from.name) next(false);
        else {
            if (pageLock.includes("/")) next(pageLock);
            else next({ name: pageLock });
        }
        return;
    }

    if (!Vue.prototype.$UserInfo.Access) {
        setTimeout(checkAccess, 200, to, from, next);
    } else {
        doCheckAcces();
    }
    function doCheckAcces() {
        switch (to.fullPath) {
            case "/io/register":
                {
                    //var register = false;
                    //var edit = false;
                    //hasAccess(webViewType("RequestIo"), (result: boolean) => {
                    //    register = result;
                    //});
                    //hasAccess(webViewType("EditIoRecordRequest"), (result: boolean) => {
                    //    edit = result;
                    //});
                    //debugger;
                    //var result = register || edit;
              
                    hasAccess(webViewType("RequestIo"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/io/registerIo":
                {
                    debugger;
                    if (Vue.prototype.$UserInfo.IsRequestableIo) {
                        debugger
                        next();
                    } else {
                        window.app.$children[0].popupNotificationWidget.show(
                            window.MessagesLibrary.get('AccessDeniedForThisAction'),
                            'error');
                        next(false);
                    }

                }
                break;
            case "/account/RemoteLoginWithPersonalCode":
                {
                    next();

                }
                break;  
            case "/account/RemoteLoginWithToken":
                {
                    next();

                }
                break;  
            case "/account/SsoRemoteLogin":
                {
                    next();

                }
                break;
            case "/account/SulRemoteLogin":
                {
                    debugger;
                    next();

                }
                break;
            case "/account/SsoHasUser":
                {
                    debugger;
                    next();

                }
                break;
               
            //case "/account/SULLogout":
            //    {
            //        debugger;
            //        next();

            //    }
            //    break;
            case "/account/RemoteLoginWithPersonalCode1":
                {

                    next();

                }
                break;
            case "/io/archive":
                {
                    hasAccess(webViewType("IoRecordArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/io/detail":
                {
                    next();

                }
                break;
            case "/io/personnel":
                {
                    hasAccess(webViewType("ViewPersonnelInoputOutputs"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/io/permission":
                {
                    hasAccess(webViewType("ViewExitPermissions"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/ExpiredRefusedRequest/index":
                {
                    hasAccess(webViewType("ShowRejectedOrExpiredRequests"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/document/register":
                {
                    var register = false;
                    var edit = false;
                    hasAccess(webViewType("DocumentRegistration"), (result: boolean) => {
                        register = result;
                    });
                    hasAccess(webViewType("EditDocumentRegistration"), (result: boolean) => {
                        edit = result;
                    });
                    debugger;
                    var result = register || edit;
                    if (!result) {
                        //@ts-ignore
                        window.app.$children[0].popupNotificationWidget.show(
                            window.MessagesLibrary.get('AccessDeniedForThisAction'),
                            'error');
                        next(false);
                    } else {
                        next();
                    }
                    //});

                }
                break;
            case "/document/archive":
                {
                    hasAccess(webViewType("DocumentRegistrationArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/document/detail":
                {
                    next();
                }
                break;
            case "/vacation/register":
                {
                    var register = false;
                    var edit = false;
                    hasAccess(webViewType("RequestVacation"), (result: boolean) => {
                      register= result;
                    });
                     hasAccess(webViewType("EditVacationRequest"), (result: boolean) => {
                        edit = result;
                    });
                    debugger;
                    var result = register || edit;
                  //  hasAccess(webViewType("RequestVacation") || ("EditVacationRequest") ,
                     //   (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        //});
                 
                }
                break;
            case "/vacation/archive":
                {
                    hasAccess(webViewType("VacationRequestArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/vacation/detail":
                {
                    next();
                }
                break;
            case "/mission/register":
                {
                    var register = false;
                    var edit = false;
                    hasAccess(webViewType("RequestMission"), (result: boolean) => {
                        register = result;
                    });
                    hasAccess(webViewType("EditMissionRequest"), (result: boolean) => {
                        edit = result;
                    });
                    debugger;
                    var result = register || edit;
                    //hasAccess(webViewType("RequestMission"),
                       // (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        //});
                }
                break;
            case "/mission/archive":
                {
                    hasAccess(webViewType("MissionRequestArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/mission/detail":
                {

                    next();

                }
                break;
            case "/mission/cartable":
                {
                    hasAccess(webViewType("MissionCartable"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/mission/confirmed":
                {
                    hasAccess(webViewType("ViewConfirmedMissions"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/missionDetail/archive":
                {
                    hasAccess(webViewType("MissionArchiveDetail"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/extraWork/register":
                {
                    hasAccess(webViewType("RequestDailyPermission"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/extraWork/archive":
                {
                    hasAccess(webViewType("DailyPermissionRequestArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/extraWork/detail":
                {

                    next();

                }
                break;
            case "/specialExtraWork/register":
                {
                    hasAccess(webViewType("RegisterSpecialExtrawork"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/specialExtraWork/archive":
                {
                    hasAccess(webViewType("RegisterSpecialExtraworkArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/specialExtraWork/detail":
                {
                    next();

                }
                break;
            case "/standBy/register":
                {
                    hasAccess(webViewType("RequestStandbyPermission"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/standBy/archive":
                {
                    hasAccess(webViewType("StandbyPermissionRequestArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/standBy/detail":
                {

                    next();
                }
                break;
            case "/workGroupRelocation/register":
                {
                    hasAccess(webViewType("WorkgroupRelocationRequest"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/workGroupRelocation/archive":
                {
                    hasAccess(webViewType("WorkgroupRelocationRequestArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/workGroupRelocation/detail":
                {
                    next();

                }
                break;
            case "/shiftRelocation/register":
                {
                    hasAccess(webViewType("ShiftRelocationRequest"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/shiftRelocation/archive":
                {
                    hasAccess(webViewType("ShiftRelocationRequestArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/shiftRelocation/detail":
                {

                    next();

                }
                break;
            case "/shiftSwap/register":
                {
                    hasAccess(webViewType("ShiftSwapRequest"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/shiftSwap/archive":
                {
                    hasAccess(webViewType("ShiftSwapRequestArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/shiftSwap/detial":
                {
                    next();

                }
                break;

            case "/custom/register":
                {
                    hasAccess(webViewType("CustomFunctinalityDetailRegistration"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/custom/archive":
                {
                    hasAccess(webViewType("CustomFunctinalityDetailRegistrationRequestArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/custom/detail":
                {

                    next();

                }
                break;


            case "/dailyfunctionality":
                {
                    hasAccess(webViewType("DailyFunctionality"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/periodfunctionality":
                {
                    hasAccess(webViewType("PeriodFunctionality"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/io/inPeriod":
                {
                    hasAccess(webViewType("PeriodicIo"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/report/dailyfunctionality":
                {
                    hasAccess(webViewType("DailyFunctionalityReport"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/report/periodfunctionality":
                {
                    hasAccess(webViewType("PeriodFunctionalityReport"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/reports/events/index":
                {
                    hasAccess(webViewType("EventsReport"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/reports/remainVacation/index":
                {
                    hasAccess(webViewType("RemainVacationReport"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/reports/ioInInterval/index":
            case "/management/reports/ioInDay/index":
                {
                    hasAccess(webViewType("IoReport"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/reports/sumDailyFunctionality/index":
                {
                    hasAccess(webViewType("SumDailyFunctionalityReport"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/reports/sumPeriodFunctionality/index":
                {
                    hasAccess(webViewType("SumPeriodFunctionalityReport"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/reports/requests/index":
                {
                    hasAccess(webViewType("RequestsReport"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/reports/attendanceStatusOfEmployees/index":
                {
                    hasAccess(webViewType("ViewTheAttendanceStatusOfEmployees"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
                
            case "/fish/Index":
                {
                    hasAccess(webViewType("ShowWinPayPayslip"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
                
            case "/management/reports/custom/index":
                {
                    hasAccess(webViewType("PersonnelCustomReports"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/report/custom/index":
                {
                    hasAccess(webViewType("CustomReports"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/report/custom/index":
                {
                    hasAccess(webViewType("PersonnelCustomReports"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/report/custom/register":
                {
                    hasAccess(webViewType("RequestReport"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/report/custom/register/archive":
                {
                    hasAccess(webViewType("ReportRequestArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/report/custom/register/detail":
                {
                    next();
                }
                break;

            case "/management/requests/vacation/index":
                {
                    hasAccess(webViewType("NotShowVacationCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/requests/mission/index":
                {
                    hasAccess(webViewType("NotShowMissionCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/requests/teleworking/index":
                {
                    hasAccess(webViewType("NotShowMissionCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/requests/missionDetail/index":
                {
                    hasAccess(webViewType("NotShowMissionCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/requests/io/index":
                {
                    hasAccess(webViewType("NotShowIoRecordCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/requests/ioRecords/index":
                {
                    hasAccess(webViewType("NotShowIoRecordCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/management/requests/io/detail":
                {
                    next();

                }
                break;
            case "/management/requests/specialExtraWork/index":
                {
                    hasAccess(webViewType("NotShowSpecialExtraworknCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/requests/extraWork/index":
                {
                    hasAccess(webViewType("NotShowDailyPermissionCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/requests/standBy/index":
                {
                    hasAccess(webViewType("NotShowStandbyPermissionCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/requests/custom/index":
                {
                    hasAccess(webViewType("NotShowCustomFunctinalityDetailCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/requests/shiftRelocation/index":
                {
                    hasAccess(webViewType("NotShowShiftRelocationCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/requests/shiftSwap/index":
                {
                    hasAccess(webViewType("NotShowShiftSwapCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/requests/workGroupRelocation/index":
                {
                    hasAccess(webViewType("NotShowWorkgroupRelocationCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/employeeAlternative/register":
                {
                    hasAccess(webViewType("JobPositionAlternateManagement"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/checkin/register":
                {
                    hasAccess(webViewType("CheckInRequest"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/checkin/archive":
                {
                    hasAccess(webViewType("CheckInRequestsArchive"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/checkin/detail":
                {

                    next();

                }
                break;

            case "/management/requests/report/index":
                {
                    hasAccess(webViewType("NotShowReportCartable"),
                        (result: boolean) => {
                            if (result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/evaluation/uptodownquestionnairesanswers":
                {
                    hasAccess(webViewType("ViewEvaluationQuestionnaireAnswers"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
            case "/evaluation/questionnaire":
                {
                    hasAccess(webViewType("EvaluationQuestionnaireAnswers"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;
                
            case "/evaluation/selfquestionnairesanswers":
                {
                    hasAccess(webViewType("ViewEvaluationSelfQuestionnaireAnswers"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            case "/management/dashboard":
                {
                    hasAccess(webViewType("DashboardView"),
                        (result: boolean) => {
                            if (!result) {
                                //@ts-ignore
                                window.app.$children[0].popupNotificationWidget.show(
                                    window.MessagesLibrary.get('AccessDeniedForThisAction'),
                                    'error');
                                next(false);
                            } else {
                                next();
                            }
                        });
                }
                break;

            default:
                {
                    next();
                }
        }
    }
}
//function getUserInfo(callback: any) {
//    $.ajax({
//        type: "POST",
//        url: "/api/Home/GetUserInfo",
//        dataType: "json",
//        success: result => {
//            if (result != null) {
//                Vue.prototype.$UserInfo = result;
//                window.access = result.Access;
//                callback && callback();
//            }
//        }
//    });
//}
