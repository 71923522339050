var appCacheIframe;

function hasSW() {
  
    return 'serviceWorker' in navigator && (
      window.location.protocol === 'https:' ||
      window.location.hostname === 'localhost' ||
      window.location.hostname.indexOf('127.') === 0
    );
  
}

function install(options) {
  options || (options = {});

  
    if (hasSW()) {
      var registration = navigator.serviceWorker
        .register(
          "\\sw.js", {
            
            
          }
        );

      

      return;
    }
  

  
}

function applyUpdate(callback, errback) {
  

  
}

function update() {
  
    if (hasSW()) {
      navigator.serviceWorker.getRegistration().then(function(registration) {
        if (!registration) return;
        return registration.update();
      });
    }
  

  
}


  setInterval(update, 3600000);


exports.install = install;
exports.applyUpdate = applyUpdate;
exports.update = update;
